


































import {
    Component,
    Vue
} from 'vue-property-decorator';
import TopSection1 from '@/views/big_screen/top-section1.vue';
import TopSection2 from '@/views/big_screen/top-section2.vue';
import TopSection3 from '@/views/big_screen/top-section3.vue';
import TopSection4 from '@/views/big_screen/top-section4.vue';
import BottomSection1 from '@/views/big_screen/bottom-section.vue';
import {throttle} from '@/util/helper';

@Component({
    name: 'big_screen',
    components: {
        TopSection1,
        TopSection2,
        TopSection3,
        TopSection4,
        BottomSection1,
    }
})
export default class extends Vue {

    brainPngIsLoaded = false;
    brainPngUrl = `${this.$cfg.baseUrl}/images/brain.png`;
    curTime='';

    defaultWidth = 2560;
    defaultHeight = 1440;
    boxStyle = {
        left: '0',
        top: '0',
        width:`${this.defaultWidth}px`,
        height: `${this.defaultHeight}px`,
        transform: `scale(1)`,
    }

    updateCommand:{
        random: number;
        command: 'real-time' | 'device' | 'hospital' | 'user' | '' ;
    } = {
        random: Math.random(),
        command: ''
    }

    created() {
        /*websocket
        *
        * 产生一个随机值，传入子组件，触发更新检查，根据服务器发来的字符串，更新相关的接口数据。
        * */
        this.loadBrainPng();
    }

    mounted() {
        window.addEventListener('resize', throttle(this.setScreenScale, 60))
        this.setScreenScale();

        this.$nextTick(()=>{
            this.updateTime();
            this.updateData();
        })

    }

    updateData() {
        this.updateRealTimeData();
        this.updateDeviceData();
        this.updateHospitalData();
        this.updateUserData();
    }

    updateRealTimeData() {
        // The next time for update,range:5s-10s.
        let nextTime = Math.floor(Math.random()*5000)+5000
        setTimeout(()=>{
            // console.log('update real time.'+ nextTime)
            this.updateCommand = {
                random: Math.random(),
                command: 'real-time'
            }
            this.updateRealTimeData();
        }, nextTime)
    }

    updateDeviceData() {
        // The next time for update,range:5s-10s.
        let nextTime = Math.floor(Math.random()*5000)+5000
        setTimeout(()=>{
            // console.log('update device.'+ nextTime)
            this.updateCommand = {
                random: Math.random(),
                command: 'device'
            }
            this.updateDeviceData();
        }, nextTime)
    }

    updateHospitalData() {
        // The next time for update,range:5s-10s.
        let nextTime = Math.floor(Math.random()*5000)+5000
        setTimeout(()=>{
            // console.log('update hospital.'+ nextTime)
            this.updateCommand = {
                random: Math.random(),
                command: 'hospital'
            }
            this.updateHospitalData();
        }, nextTime)
    }

    updateUserData() {
        // The next time for update,range:5s-10s.
        let nextTime = Math.floor(Math.random()*5000)+5000
        setTimeout(()=>{
            // console.log('update user.'+ nextTime)
            this.updateCommand = {
                random: Math.random(),
                command: 'user'
            }
            this.updateUserData();
        }, nextTime)
    }

    updateTime() {
        // console.log(this.$day(new Date()).format('YYYY年MM月DD日 HH:mm:ss'));
        setInterval(()=>{
            this.curTime = this.$day(new Date()).format('YYYY年MM月DD日 HH:mm:ss')
        },1000)
    }

    destroy() {
        window.removeEventListener('resize', throttle(this.setScreenScale, 60))
    }

    setScreenScale() {
        let winWidth =  document.documentElement.offsetWidth || document.body.offsetWidth;
        let winHeight =  document.documentElement.offsetHeight || document.body.offsetHeight;
        let winRatio = (winWidth/winHeight).toFixed(5);
        let defaultRatio = (this.defaultWidth/this.defaultHeight).toFixed(5);
        let boxLeft,boxTop,boxScale;

        // console.log(winWidth,winHeight);
        // console.log(winRatio,defaultRatio, (winRatio/defaultRatio));
        if(winRatio < defaultRatio) {
            boxScale = (winWidth/this.defaultWidth).toFixed(5);
        }else {
            boxScale = (winHeight/this.defaultHeight).toFixed(5);
        }

        boxLeft = ((winWidth - this.defaultWidth) /2).toFixed(5)
        boxTop = ((winHeight - this.defaultHeight) /2).toFixed(5);
        Object.assign(this.boxStyle,{
            transform: `scale(${boxScale})`,
            left: `${boxLeft}px`,
            top: `${boxTop}px`
        })
    }

    loadBrainPng() {

        let img=new Image();
        img.src=this.brainPngUrl;
        console.log(this.brainPngUrl);
        img.onload = ()=>{
            if(img.width > 0) {
                this.brainPngIsLoaded = true;
            }
        }
    }

}

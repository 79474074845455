



























































	import {
		Component,
        Prop,
        Watch,
		Vue
	} from 'vue-property-decorator';

	@Component({
		name: 'top_section1'
	})
	export default class extends Vue {
	    @Prop({default: {random: 0,command: ''}}) updateCommand!:{
            random: number;
            command: 'real-time' | 'device' | 'hospital' | 'user' | '' ;
        };

	    user = {
	        online: 0,
            todayAdded: 0
        }

        device = {
	        todayAdded: 0,
            total: 0
        }

        measure = {
	        todayAdded: 0,
            total: 0
        }

        registeredAmount = {
	        today: 0,
            yesterday:0
        }

        pharmaceuticalAmount = {
	        today: 0,
            yesterday: 0
        }

        get realTimeData() {
	        return this.$store.state.bigScreen.realTimeData
        }

        @Watch('realTimeData')
        changeRealTimeData(){
            this.user = {
                online: this.realTimeData.user_online,
                todayAdded:  this.realTimeData.user_today_added
            }

            this.device = {
                todayAdded:  this.realTimeData.device_today_added,
                total:  this.realTimeData.device_total
            }

            this.measure = {
                todayAdded:  this.realTimeData.measure_today_added,
                total:  this.realTimeData.measure_total
            }

            this.registeredAmount = {
                today:  this.realTimeData.registered_amount_today,
                yesterday: this.realTimeData.registered_amount_yesterday
            }

            this.pharmaceuticalAmount = {
                today:  this.realTimeData.pharmaceutical_amount_today,
                yesterday:  this.realTimeData.pharmaceutical_amount_yesterday
            }
        }


        @Watch('updateCommand')
        changeUpdateCommand(val:{
            random: number;
            command: 'real-time' | 'device' | 'hospital' | 'user' ;
        }) {
	        // console.log(val);
	        if(val.command === 'real-time') {
	            this.updateRealTimeData();
            }
        }

        mounted() {
            this.updateRealTimeData();
        }

		updateRealTimeData() {
            this.$store.dispatch('bigScreen/getRealTimeData', {vm: this})
        }

	}

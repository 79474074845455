









































import {
    Component,
    Prop,
    Watch,
    Vue
} from 'vue-property-decorator';

@Component({
    name: 'top_section4'
})
export default class extends Vue {

    /* circle color array*/
    colors = [
        '92, 171, 239',
        '92, 171, 239',
        '249, 123, 65',
        '249, 123, 65',
    ]

    get otherData() {
        return this.$store.state.bigScreen.hospitalOtherData;
    }
}





















































import {
    Component,
    Prop,
    Watch,
    Vue
} from 'vue-property-decorator';

@Component({
    name: 'top_section2'
})
export default class extends Vue {
    @Prop({default: {random: 0, command: ''}}) updateCommand!: {
        random: number;
        command: 'real-time' | 'device' | 'hospital' | 'user' | '';
    };

    holdDeviceChartList: any[] = []
    alarmChart = {}

    holdDeviceChartTheme = {
        color: [
            '#5FE48C',
            '#585858'
        ],
    }
    alarmChartTheme = {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#C97006' // 0% 处的颜色
            }, {
                offset: 1, color: '#FFCE70' // 100% 处的颜色
            }],
            global: false // 缺省为 false
        },
        categoryAxis: {
            axisLabel: {
                fontSize: '20px',
                color: '#fff'
            },
            axisTick: {
                show: false,
            },
        },
        valueAxis: {
            nameTextStyle: {
                fontSize: '16px',
                color: '#ffffff'
            },
            axisLabel: {
                fontSize: '16px',
                color: '#fff'
            },
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                interval: 1,
                lineStyle: {
                    color: ['transparent','#fff'],
                    type: [3,5], // 虚线样式[线，间隔]
                    opacity: '1'
                },
            }
        },
    }

    get deviceUsage() {
        return this.$store.state.bigScreen.deviceUsageData;
    }

    get deviceHoldList() {
        return this.$store.state.bigScreen.deviceHoldData;
    }

    get deviceAlarm() {
        return this.$store.state.bigScreen.deviceAlarmData;
    }

    @Watch('deviceHoldList')
    changeDeviceHold() {
        this.setHoldDeviceChartData();
    }

    @Watch('deviceAlarm')
    changeDeviceAlarm() {
        this.setAlarmChartData();
    }

    @Watch('updateCommand')
    changeUpdateCommand(val: {
        random: number;
        command: 'real-time' | 'device' | 'hospital' | 'user';
    }) {
        if (val.command === 'device') {
            this.updateDeviceData();
        }
    }

    mounted() {
        this.updateDeviceData();
    }

    updateDeviceData() {
        this.$store.dispatch('bigScreen/getDeviceData', {vm: this})
    }

    setHoldDeviceChartData() {
        this.holdDeviceChartList = []
        this.deviceHoldList.forEach((item: any) => {
            this.holdDeviceChartList.push(buildData(item));
        })

        function buildData(opt: { [key in string]: any }): any {
            // console.log(opt)
            let data = opt.data;
            let percent = Math.floor(data[0].value/(data[0].value+data[1].value)*10000)/100
            return {
                graphic:[
                    {
                        type: 'group',
                        bounding: 'raw',
                        top: 30,
                        left: 140,
                        z: 100,
                        children: [
                            {
                                type: 'text',
                                left: 'left',
                                top: 'center',
                                style: {
                                    fill: '#fff',
                                    text: `${opt.title}`,
                                    font: 'bold 26px sans-serif'
                                }
                            }

                        ]
                    },
                    {
                        type: 'group',
                        bounding: 'raw',
                        top: 60,
                        left: 140,
                        z: 100,
                        children: [
                            {
                                type: 'text',
                                y: '0',
                                style: {
                                    fill: '#fff',
                                    text: `持有数量:${percent}% `,
                                    fontSize: '20px',
                                }
                            },
                            {
                                type: 'text',
                                y: '25',
                                style: {
                                    fill: '#fff',
                                    text: `累计测量${opt.data[0].total}次`,
                                    fontSize: '20px',
                                }
                            },

                        ]
                    },
                ],
                series: [
                    {
                        name: '持有率',
                        type: 'pie',
                        radius: '80%',
                        center: ['16%', '50%'],
                        label: {
                            show: false,
                            formatter(params: any) {
                                if (!params.name) return '';
                                return `${params.seriesName}:${params.percent}% \n累计测量${params.data.total}次`
                            },
                            fontSize: '16px',
                            color: '#fff',
                            textBorderColor: 'transparent',
                        },
                        labelLine: {
                            show: false,
                            length: 0,
                            length2: 10
                        },
                        data: opt.data,
                    }
                ]
            }
        }
    }

    setAlarmChartData() {
        let deviceAlarmData = this.deviceAlarm;
        // console.log(deviceAlarmData);
        this.alarmChart = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            grid: {
                top: '1%',
                left: '1%',
                right: '12%',
                bottom: '0',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                name: '(次)',
            },
            yAxis: {
                type: 'category',
                inverse: true,
                data: deviceAlarmData.yAxis,
            },
            series: [
                {
                    name: '报警次数',
                    type: 'bar',
                    barWidth: '50%',
                    // realtimeSort: true,
                    label: {
                        show: false,
                        position: 'insideRight',
                        fontSize: '20px',
                        color: '#666',
                        textBorderColor: 'transparent',
                    },
                    data: deviceAlarmData.data,
                }
            ]
        };
    }

}

export const defaultTheme = {
    "color": [
        "#dd6b66",
        "#759aa0",
        "#e69d87",
        "#8dc1a9",
        "#ea7e53",
        "#eedd78",
        "#73a373",
        "#73b9bc",
        "#7289ab",
        "#91ca8c",
        "#f49f42"
    ],
    // "backgroundColor": "#181a20",
    "textStyle": {
        "fontSize": '16px',
    },
    "title": {
        "textStyle": {
            'fontSize': '20px',
            "color": "#fff"
        },
        "subtextStyle": {
            "color": "#aaaaaa"
        }
    },
    "line": {
        "itemStyle": {
            "borderWidth": "1"
        },
        "lineStyle": {
            "width": "1"
        },
        "symbolSize": "8",
        "symbol": "circle",
        "smooth": false
    },
    "radar": {
        "itemStyle": {
            "borderWidth": "1"
        },
        "lineStyle": {
            "width": "2"
        },
        "symbolSize": "10",
        "symbol": "circle",
        "smooth": false
    },
    "bar": {
        "itemStyle": {
            "barBorderWidth": 0,
            "barBorderColor": "#ccc"
        }
    },
    "pie": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "scatter": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "boxplot": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "parallel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "sankey": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "funnel": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "gauge": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        }
    },
    "candlestick": {
        "itemStyle": {
            "color": "#fd1050",
            "color0": "#0cf49b",
            "borderColor": "#fd1050",
            "borderColor0": "#0cf49b",
            "borderWidth": 1
        }
    },
    "graph": {
        "itemStyle": {
            "borderWidth": 0,
            "borderColor": "#ccc"
        },
        "lineStyle": {
            "width": 1,
            "color": "#aaaaaa"
        },
        "symbolSize": "10",
        "symbol": "circle",
        "smooth": false,
        "color": [
            "#dd6b66",
            "#759aa0",
            "#e69d87",
            "#8dc1a9",
            "#ea7e53",
            "#eedd78",
            "#73a373",
            "#73b9bc",
            "#7289ab",
            "#91ca8c",
            "#f49f42"
        ],
        "label": {
            "color": "#eeeeee"
        }
    },
    "map": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        }
    },
    "geo": {
        "itemStyle": {
            "areaColor": "#eee",
            "borderColor": "#444",
            "borderWidth": 0.5
        },
        "label": {
            "color": "#000"
        }
    },
    "categoryAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#ffffff"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(73,79,93,0.15)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "valueAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#ffffff"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(73,79,93,0.15)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "logAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#ffffff"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(73,79,93,0.15)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "timeAxis": {
        "axisLine": {
            "show": true,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisTick": {
            "show": false,
            "lineStyle": {
                "color": "#494f5d"
            }
        },
        "axisLabel": {
            "show": true,
            "color": "#ffffff"
        },
        "splitLine": {
            "show": true,
            "lineStyle": {
                "color": [
                    "rgba(73,79,93,0.15)"
                ]
            }
        },
        "splitArea": {
            "show": false,
            "areaStyle": {
                "color": [
                    "#eeeeee"
                ]
            }
        }
    },
    "toolbox": {
        "iconStyle": {
            "borderColor": "#999999"
        }
    },
    "legend": {
        "textStyle": {
            "color": "#ffffff"
        }
    },
    "tooltip": {
        "axisPointer": {
            "lineStyle": {
                "color": "#eeeeee",
                "width": "1"
            },
            "crossStyle": {
                "color": "#eeeeee",
                "width": "1"
            }
        }
    },
    "timeline": {
        "lineStyle": {
            "color": "#ffffff",
            "width": 1
        },
        "itemStyle": {
            "color": "#dd6b66",
            "borderWidth": 1
        },
        "controlStyle": {
            "color": "#eeeeee",
            "borderColor": "#eeeeee",
            "borderWidth": 0.5
        },
        "checkpointStyle": {
            "color": "#e43c59",
            "borderColor": "rgba(194,53,49,0.5)"
        },
        "label": {
            "color": "#ffffff"
        }
    },
    "visualMap": {
        "color": [
            "#bf444c",
            "#d88273",
            "#f6efa6"
        ]
    },
    "dataZoom": {
        "backgroundColor": "rgba(47,69,84,0)",
        "dataBackgroundColor": "rgba(255,255,255,0.3)",
        "fillerColor": "rgba(167,183,204,0.4)",
        "handleColor": "#a7b7cc",
        "handleSize": "100%",
        "textStyle": {
            "color": "#eeeeee"
        }
    },
    "markPoint": {
        "label": {
            "color": "#eeeeee"
        },
    }
}





































	import {
		Component,
        Prop,
        Watch,
		Vue
	} from 'vue-property-decorator';
    import  { defaultTheme } from '@/util/echart_themes'

	@Component({
		name: 'top_section3'
	})
	export default class extends Vue {
        @Prop({default: {random: 0,command: ''}}) updateCommand!:{
            random: number;
            command: 'real-time' | 'device' | 'hospital' | 'user' | '' ;
        }   ;

        registerTotalChart = {}
	    saleCategoryTotalChart = {}

        registerTotalChartTheme = {
            color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 1,
                colorStops: [{
                    offset: 0, color: '#7E99FB' // 0% 处的颜色
                }, {
                    offset: 1, color: '#355EF6' // 100% 处的颜色
                }],
                global: false // 缺省为 false
            },
            categoryAxis: {
                axisLabel: {
                    fontSize: '20px',
                    color: '#fff'
                },
                axisTick: {
                    show: false,
                },
            },
            valueAxis: {
                nameTextStyle: {
                    fontSize: '16px',
                    color: '#ffffff'
                },
                axisLabel: {
                    fontSize: '16px',
                    color: '#fff'

                },
                axisLine: {
                    show: true,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    interval: 1,
                    lineStyle: {
                        color: ['transparent','#fff'],
                        type: [3,5], // 虚线样式[线，间隔]
                        opacity: '1'
                    },
                }
            },
        }
        defaultTheme=defaultTheme

        get registerData() {
            return this.$store.state.bigScreen.registerData;
        }
        get saleCategoryData() {
            return this.$store.state.bigScreen.saleCategoryData;
        }
        get saleData() {
            return this.$store.state.bigScreen.saleData;
        }

        @Watch('registerData')
        changeRegisterData() {
            this.setRegisterTotalData();
        }

        @Watch('saleCategoryData')
        changeSaleCategoryData() {
            this.setSaleCategoryTotalData()
        }


        @Watch('updateCommand')
        changeUpdateCommand(val:{
            random: number;
            command: 'real-time' | 'device' | 'hospital' | 'user' ;
        }) {
            if(val.command === 'hospital') {
                this.updateHospitalData();
            }
        }

        mounted() {
            this.updateHospitalData();
        }

        updateHospitalData() {
            this.$store.dispatch('bigScreen/getHospitalData', {vm: this})
        }

        setRegisterTotalData() {
	        let registerData = this.registerData;
            this.registerTotalChart = {
                "color": [
                    "#3446a4",
                    "#eaeaea",
                ],
                grid: {
                    top: '17%',
                    left: '1%',
                    right: '2%',
                    bottom: '0',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        inverse: true,
                        data: registerData.xAxis,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            fontSize: '18px'
                        },
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '(人次)',
                        nameTextStyle: {
                            color: '#ffffff'
                        },
                        axisLabel: {
                            fontSize: '16px',
                        }
                    }
                ],
                series: [
                    {
                        name: '挂号人数',
                        type: 'bar',
                        barWidth: '50%',
                        data: registerData.data
                    }
                ]
            };
        }

        setSaleCategoryTotalData() {
	        let saleCategoryData= this.saleCategoryData;
            this.saleCategoryTotalChart = {
                "color": [
                    "#30AAF0",
                    "#FFCE70",
                    "#7455EF",
                ],
                grid: {
                    top: '4%',
                    left: '1%',
                    right: '2%',
                    bottom: '0',
                    containLabel: true
                },
                series: [
                    {
                        name: '药品销售',
                        type: 'pie',
                        radius: ['50%', '80%'],
                        label: {
                            fontSize: '20px',
                            color: '#fff',
                            textBorderColor: 'transparent',
                            formatter(params:any){
                                return `${params.name}:${params.percent}%`
                            },
                        },
                        data: saleCategoryData
                    }
                ]
            };
        }

	}























import {
    Component,
    Prop,
    Watch,
    Vue
} from 'vue-property-decorator';
import {defaultTheme} from '@/util/echart_themes'

@Component({
    name: 'bottom_section1'
})
export default class extends Vue {
    @Prop({default: {random: 0,command: ''}}) updateCommand!:{
        random: number;
        command: 'real-time' | 'device' | 'hospital' | 'user' | '' ;
    };

    genderChartData = {};
    visits24HChartData = {};
    activeUser7DChartData = {};
    ageChartData = {};

    lineChartTheme = {
        categoryAxis: {
            axisLabel: {
                fontSize: '20px',
                color: '#fff'
            },
            axisTick: {
                show: false,
            },
        },
        valueAxis: {
            nameTextStyle: {
                fontSize: '16px',
                color: '#ffffff'
            },
            axisLabel: {
                fontSize: '16px',
                color: '#fff'

            },
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                interval: 1,
                lineStyle: {
                    color: ['#fff'],
                    type: [3,5], // 虚线样式[线，间隔]
                    opacity: '1'
                },
            }
        },
    }

    ageChartTheme = {
        color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 1,
            y2: 1,
            colorStops: [{
                offset: 0, color: '#7E99FB' // 0% 处的颜色
            }, {
                offset: 1, color: '#355EF6' // 100% 处的颜色
            }],
            global: false // 缺省为 false
        },
        categoryAxis: {
            axisLabel: {
                fontSize: '20px',
                color: '#fff'
            },
            axisTick: {
                show: false,
            },
        },
        valueAxis: {
            nameTextStyle: {
                fontSize: '16px',
                color: '#ffffff'
            },
            axisLabel: {
                fontSize: '16px',
                color: '#fff'

            },
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                interval: 1,
                lineStyle: {
                    color: ['transparent','#fff'],
                    type: [3,5], // 虚线样式[线，间隔]
                    opacity: '1'
                },
            }
        },
    }
    defaultTheme = defaultTheme;

    // visits24HData: {},
    // activeUser7DData: {},
    // ageData: {},
    // genderData: {}

    get visits24HData() {
        return this.$store.state.bigScreen.visits24HData;
    }
    get activeUser7DData() {
        return this.$store.state.bigScreen.activeUser7DData;
    }

    get ageData() {
        return this.$store.state.bigScreen.ageData;
    }

    get genderData() {
        return this.$store.state.bigScreen.genderData;
    }

    @Watch('visits24HData')
    changeVisits24HData(){
        this.setVisits24HChart();
    }

    @Watch('activeUser7DData')
    changeActiveUser7DData(){
        this.setActiveUser7DChart();
    }

    @Watch('ageData')
    changeAgeData(){
        this.setAgeChart();
    }

    @Watch('genderData')
    changeGenderData(){
        this.setGenderChart();
    }

    @Watch('updateCommand')
    changeUpdateCommand(val:{
        random: number;
        command: 'real-time' | 'device' | 'hospital' | 'user' ;
    }) {
        // console.log(val);
        if(val.command === 'user') {
            this.updateUserData();
        }
    }

    mounted() {
        this.updateUserData();
    }

    updateUserData() {
        this.$store.dispatch('bigScreen/getUserData', {vm: this})
    }

    setGenderChart() {
        let genderData = this.genderData;

        this.genderChartData = {
            "color": [
                "#5EB0DF",
                "#393939",
                "#D3518F",
            ],
            title: {
                text: `总用户量`,
                subtext: genderData.total,
                textStyle: {
                    fontSize: '20px',
                    fontWeight: 'normal'
                },
                subtextStyle: {
                    fontSize: '24px',
                    color: '#fff',
                },
                left: '50%',
                top: '36%',
                textAlign: 'center',
                fontWeight: 'normal'
            },
            series: [
                {
                    name: '用户性别',
                    type: 'pie',
                    radius: ['60%', '85%'],
                    startAngle: '70',
                    label: {
                        fontSize: '20px',
                        color: '#fff',
                        textBorderColor: 'transparent',
                        formatter(params:any){
                            return `${params.name}:${params.percent}%`
                        },
                    },
                    data: genderData.data
                },
            ]

        };
    }

    setVisits24HChart() {
        let visites24HData = this.visits24HData;
        this.visits24HChartData = {
            color: ['#5FE48D'],
            grid: {
                top: '15%',
                left: '1%',
                right: '2%',
                bottom: '0',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: visites24HData.xAxis,
                axisLabel: {
                    fontSize: '18px'
                },
            },
            yAxis: {
                type: 'value',
                name: '(人数)',
                nameTextStyle: {
                    color: '#ffffff'
                },
                axisLabel: {
                    fontSize: '20px'
                },
            },
            series: [
                {
                    data: visites24HData.data,
                    type: 'line'
                }
            ]
        };
    }

    setActiveUser7DChart() {
        let activeUser7DData= this.activeUser7DData;

        this.activeUser7DChartData = {
            color: ['#F97B41'],
            grid: {
                top: '15%',
                left: '1%',
                right: '2%',
                bottom: '0',
                containLabel: true
            },
            xAxis: {
                type: 'category',
                data: activeUser7DData.xAxis,
                axisLabel: {
                    fontSize: '20px'
                },
            },
            yAxis: {
                type: 'value',
                name: '(人数)',
                nameTextStyle: {
                    color: '#ffffff'
                },
                axisLabel: {
                    fontSize: '18px'
                },
            },
            series: [
                {
                    data: activeUser7DData.data,
                    type: 'line'
                }
            ]
        };
    }

    setAgeChart() {
        let ageData = this.ageData;

        this.ageChartData = {
            "color": [
                "#0250d8",
                "#eaeaea",
            ],
            grid: {
                top: '15%',
                left: '1%',
                right: '8%',
                bottom: '0',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: ageData.xAxis,
                    name: '(岁)',
                    nameTextStyle: {
                        color: '#ffffff'
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLabel: {
                        fontSize: '20px'
                    },
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '(人数)',
                    nameTextStyle: {
                        color: '#ffffff'
                    },
                    axisLabel: {
                        fontSize: '20px'
                    },
                }
            ],
            series: [
                {
                    name: '人数',
                    type: 'bar',
                    barWidth: '50%',
                    data: ageData.data,
                }
            ]
        };
    }
}
